import { Injectable, signal } from "@angular/core";
import { AppointmentService } from "./appointment.service";
import { Appointment } from "./appointment.model";

@Injectable()
export class AppointmentMockService implements AppointmentService {
  
  public dataChangesAreMonitored = signal<boolean>(false);

  public data = signal<Appointment[]>([]);

  private appointments: Appointment[] = [];

  constructor() {
    
    const currentDate = new Date();
    const pastDate = new Date(currentDate);
    pastDate.setMinutes(currentDate.getMinutes() - 5);
    this.appointments.push({
      appointmentDatetime: pastDate,
      vehicle: {
        id: "1",
        vin: "JN1GANR35A0460001",
        licensePlate: "M-TT1177",
        carType: "Z4",
        pairing: null,
        streetAddress: "Berliner Weg 3",
        visitReason: "Gast",
        customer: {
          id: "1",
          firstName: "Max",
          lastName: "Mustermann",
        },
      }
    });

    const nearDate = new Date(currentDate);
    nearDate.setMinutes(currentDate.getMinutes() + 5);
    this.appointments.push({
      appointmentDatetime: nearDate,
      vehicle: {
        id: "1",
        vin: "JN1GANR35A0460001",
        licensePlate: "M-TT1177",
        carType: "Z4",
        pairing: null,
        streetAddress: "Berliner Weg 3",
        visitReason: "Service",
        customer: {
          id: "1",
          firstName: "Max",
          lastName: "Mustermann",
        },
      }
    });

    const futureDate = new Date(currentDate);
    futureDate.setMinutes(currentDate.getMinutes() + 30);
    this.appointments.push({
      appointmentDatetime: futureDate,
      vehicle: {
        id: "1",
        vin: "JN1GANR35A0460001",
        licensePlate: "M-TT1177",
        carType: "Z4",
        pairing: null,
        streetAddress: "Berliner Weg 3",
        visitReason: "Service",
        customer: {
          id: "1",
          firstName: "Max",
          lastName: "Mustermann",
        },
      }
    });
  }

  public async loadAll(): Promise<Appointment[]> {
    this.data.set(structuredClone(this.appointments));

    setTimeout(() => {
      this.data.set([
        ...this.appointments,
        {
          appointmentDatetime: new Date("2024-07-15T14:40:00Z"),
          vehicle: {
            id: "1",
            vin: "JN1GANR35A0460001",
            licensePlate: "M-TT1177",
            carType: "Z4",
            pairing: null,
            streetAddress: "Berliner Weg 3",
            visitReason: "Gast",
            customer: {
              id: "1",
              firstName: "Lisa",
              lastName: "Musterfrau",
            },
          }
        }
      ]);
    }, 5000);
    return this.data();
  }
}
