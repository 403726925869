import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { DsIconModule } from '@bmw-ds/components';
import { Lane } from '../shared/streams/lane.model';

@Component({
  selector: 'app-direction-cell-renderer',
  standalone: true,
  imports: [DsIconModule],
  templateUrl: './direction-cell-renderer.component.html',
  styleUrl: './direction-cell-renderer.component.scss'
})
export class DirectionCellRendererComponent {
  params?: ICellRendererParams<undefined, Lane>;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    return true;
  }
}
