<h1>Besucher & Termine</h1>
<div class="row">
  <div class="col-11 mb-5x">
    <ds-form-field [optionalText]="' '">
      <ds-checkbox-group
        [isBlock]="true"
        variant="inline"
        name="viewSelection"
      >
        <input
          ds-input
          id="all"
          type="checkbox"
          name="viewSelectionAll"
          [value]="true"
          [ngModel]="viewSelectionAll()"
          (ngModelChange)="setViewSelectionAll($event)"
        />
        <label ds-label for="all">Alle Termine ({{appointmentsCount()}})</label>
        <input
          ds-input
          id="today"
          type="checkbox"
          name="viewSelectionToday"
          [value]="true"
          [ngModel]="viewSelectionToday()"
          (ngModelChange)="setViewSelectionToday($event)"
        />
        <label ds-label for="today">Termine heute ({{appointmentsTodayCount()}})</label>
        <input
          ds-input
          id="tomorrow"
          type="checkbox"
          name="viewSelectionTomorrow"
          [value]="true"
          [ngModel]="viewSelectionTomorrow()"
          (ngModelChange)="setViewSelectionTomorrow($event)"
        />
        <label ds-label for="tomorrow">Termine morgen ({{appointmentsTomorrowCount()}})</label>
        <input
          ds-input
          id="open"
          type="checkbox"
          name="viewSelectionOpen"
          [value]="true"
          [ngModel]="viewSelectionOpen()"
          (ngModelChange)="setViewSelectionOpen($event)"
        />
        <label ds-label for="open">Offene Termine ({{appointmentsOpenCount()}})</label>
        <input
          ds-input
          id="inHouse"
          type="checkbox"
          name="viewSelectionInHouse"
          [value]="true"
          [ngModel]="viewSelectionInHouse()"
          (ngModelChange)="setViewSelectionInHouse($event)"
        />
        <label ds-label for="inHouse">Kunden im Haus ({{appointmentsInHouseCount()}})</label>
      </ds-checkbox-group>
    </ds-form-field>
  </div>
  <div class="col-1 align-content-center text-end mb-5x">
    <button type="button" ds-button variant="ghost" icon="download" (click)="exportGridToCsv()">
      <span></span>
    </button>
  </div>
  <ds-box>
    <ds-box-content>
      <div class="col-12">
        <app-visitors-and-appointments-ag-grid
          [appointments]="appointments()"
        ></app-visitors-and-appointments-ag-grid>
      </div>
    </ds-box-content>
  </ds-box>
</div>
