import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { TitleStrategy, provideRouter } from '@angular/router';

import { routes } from './app.routes';

import {
 DsImprintModule, 
} from '@bmw-ds/components';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient } from '@angular/common/http';
import { CustomTitleStrategy } from './custom-title.strategy';
import { environment } from '../environments/environment';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes), 
    provideHttpClient(),
    provideAnimations(),
    {
      provide: TitleStrategy,
      useClass: CustomTitleStrategy,
    },
    BrowserAnimationsModule,
    provideAnimations(),
    importProvidersFrom(
      DsImprintModule.forRoot({
      phone: '5555555',
      electronicContact: 'change-me@bmwgroup.com'
      }), 
    ),
    ...environment.providers,
  ],
};
