<div ds-loading-area-container>
  <ag-grid-angular
     class="ag-theme-density"
      domLayout="autoHeight"
      [rowData]="appointments()"
      [columnDefs]="columns()"
      [gridOptions]="gridOptions()"
      [defaultColDef]="defaultColDef()"
      [statusBar]="statusBar"
      [sideBar]="sideBar"
      (gridReady)="onGridReady($event)"
      [autoSizeStrategy]="autoSizeStrategy"
      >
  </ag-grid-angular>
</div>
