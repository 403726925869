import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DsBoxModule, DsButtonModule } from '@bmw-ds/components';
import { Lane } from '../shared/streams/lane.model';

@Component({
  selector: 'app-stream-card',
  standalone: true,
  imports: [
    CommonModule,
    DsButtonModule,
    DsBoxModule,
  ],
  templateUrl: './stream-card.component.html',
  styleUrl: './stream-card.component.scss'
})
export class StreamCardComponent {
  @Input() stream!: Lane;

  constructor() {}
}
