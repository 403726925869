import { Injectable } from '@angular/core';

import { PairingUpsert } from './pairing-upsert.model';

@Injectable({
  providedIn: 'root'
})
export abstract class PairingService {

  /**
   * Creates or updates an existing pairing
   * @param licensePlate 
   * @param pairing 
   * @param isNew true if is new pairing
   */
  public abstract upsertPairing(licensePlate: string, pairing: PairingUpsert, isNew: boolean): Promise<void>;
}
