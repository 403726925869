import { computed, Injectable } from '@angular/core';
import { BaseBackendService } from '../base-backend/base-backend.service';
import { LocationProvider } from './location-provider.model';
import { LocationProviderService } from './location-provider.service';

@Injectable()
export class LocationProviderBackendService extends BaseBackendService<LocationProvider> implements LocationProviderService {

    protected override getEndpoint() {
        return "v1/locationprovider/list";
    }

    public locationProviderMap = computed<Map<string, LocationProvider>>(() => {
        const map = new Map<string, LocationProvider>();
        const data = this.data();
        for (const location of data) {
            map.set(location.name, location);
        }
        return map;
    });

    public async isValidName(value: string): Promise<boolean> {
        const isLoaded = this.data().length > 0;
        if (!isLoaded) {
            await this.loadAll();
        }
        return this.locationProviderMap().has(value);
    }
}
