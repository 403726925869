import { Routes } from '@angular/router';


import { VehiclesComponent } from './vehicles/vehicles.component';
import { VehiclesNotpairedComponent } from './vehicles-notpaired/vehicles-notpaired.component';
import { VehiclesAllComponent } from './vehicles-all/vehicles-all.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { VisitorsAndAppointmentsComponent } from './visitors-and-appointments/visitors-and-appointments.component';
import { RoadwaysComponent } from './roadways/roadways.component';
import { VehiclesOnsiteComponent } from './vehicles-onsite/vehicles-onsite.component';

export const routes: Routes = [
  { path: 'roadways', component: RoadwaysComponent },
  {
    path: 'vehicles', component: VehiclesComponent, children: [
      { path: '', component: VehiclesAllComponent },
      { path: 'all', component: VehiclesAllComponent },
      { path: 'onsite', component: VehiclesOnsiteComponent },
      { path: 'notpaired', component: VehiclesNotpairedComponent },
    ]
  },
  {
    path: 'visitorsandappointments', component: VisitorsAndAppointmentsComponent
  },
  { path: '',   redirectTo: '/roadways', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];
