<ds-box>
    <ds-box-header>Erwartete Ankünfte - Termine</ds-box-header>
    <ds-box-content>
        <ds-tabs>
            <ds-tabs-label for="appointments-all">Alle</ds-tabs-label>
            <ds-tabs-content id="appointments-all">
                <div class="row w-100">
                    <div *ngFor="let appointment of appointments()" class="col-12">
                        <div class="spp-appointment-container mb-2x">
                            <app-appointment [appointment]="appointment"></app-appointment>
                        </div>
                    </div>
                </div>
            </ds-tabs-content>
            <ds-tabs-label for="appointments-incoming">Bevorstehende</ds-tabs-label>
            <ds-tabs-content id="appointments-incoming">
                <div class="row w-100">
                    <div *ngFor="let appointment of impendingAppointments()" class="col-12">
                        <div class="spp-appointment-container mb-2x">
                            <app-appointment [appointment]="appointment"></app-appointment>
                        </div>
                    </div>
                </div>
            </ds-tabs-content>
            <ds-tabs-label for="appointments-late">Verspätet</ds-tabs-label>
            <ds-tabs-content id="appointments-late">
                <div class="row w-100">
                    <div *ngFor="let appointment of lateAppointments()" class="col-12">
                        <div class="spp-appointment-container mb-2x">
                            <app-appointment [appointment]="appointment"></app-appointment>
                        </div>
                    </div>
                </div>
            </ds-tabs-content>
        </ds-tabs>
    </ds-box-content>
</ds-box>
