import { Directive, forwardRef, Injectable } from "@angular/core";
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from "@angular/forms";
import { LocationProviderService } from "./location-provider/location-provider.service";

@Injectable({providedIn: 'root'})
export class LocationNameValidator implements AsyncValidator {
  constructor(private locationProvider: LocationProviderService) {}
  
  async validate(control: AbstractControl<number>): Promise<ValidationErrors | null> {
    if (await this.locationProvider.isValidName(control.value.toString())) {
      return null;
    }
    return {
      validName: true,
    }
  }
}

@Directive({
  selector: '[appValidLocationName]',
  standalone: true,
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => ValidLocationNameValidatorDirective),
      multi: true,
    },
  ],
})
export class ValidLocationNameValidatorDirective implements AsyncValidator {
  constructor(private validator: LocationNameValidator) {}
  validate(control: AbstractControl): Promise<ValidationErrors | null> {
    return this.validator.validate(control);
  }
}
