import { VehicleStayBackendService } from "../app/shared/vehicle-stay/vehicle-stay.backend.service";
import { VehicleStayService } from "../app/shared/vehicle-stay/vehicle-stay.service";
import { PairingBackendService } from "../app/shared/pairing/pairing.backend.service";
import { Environment } from "./ienvironment";
import { PairingService } from "../app/shared/pairing/pairing.service";
import { DateService } from "../app/shared/date/date.service";
import { AppointmentMockService } from "../app/shared/appointment/appointment.mock.service";
import { AppointmentService } from "../app/shared/appointment/appointment.service";
import { LaneMockService } from "../app/shared/streams/lane.mock.service";
import { LaneService } from "../app/shared/streams/lane.service";
import { LocationProviderService } from "../app/shared/location-provider/location-provider.service";
import { LocationProviderBackendService } from "../app/shared/location-provider/location-provider.backend.service";

export const environment: Environment = {
  environmentName: 'Dev',
  apiUrl: 'https://spp-backend.c2-cloud.de/grt/',
  wsUrl: 'wss://spp-backend.c2-cloud.de/grt/',
  // wsUrl: 'wss://ws.postman-echo.com/raw/',
  reconnect: {
    retries: 10,
    delayInSeconds: 5000,
  },
  providers: [
    { provide: VehicleStayService, useClass: VehicleStayBackendService },
    { provide: PairingService, useClass: PairingBackendService },
    { provide: DateService, useClass: DateService},
    { provide: LaneService, useClass: LaneMockService},
    { provide: AppointmentService, useClass: AppointmentMockService},
    { provide: LocationProviderService, useClass: LocationProviderBackendService },
  ],
  keycloak: {
    enabled: true,
    config: {
      url: 'https://www.myurl.invalid/',
      realm: 'grt',
      clientId: 'frontend'
    },
  }
};
