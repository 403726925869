import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {
  DsHeaderModule, DsBoxModule, DsButtonModule, DsNavigationBarModule, DsHeaderTagConfiguration, DsNavigationItem,
} from '@bmw-ds/components';
import { environment } from '../environments/environment';
import 'ag-grid-community';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    DsHeaderModule,
    DsBoxModule,
    DsButtonModule,
    DsNavigationBarModule,
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  //Tag with environment for header
  environmentTagConfig: Partial<DsHeaderTagConfiguration> = { label: environment.environmentName };
  userInfo = {
    username: 'John Doe',
    department: 'Dept 1',
    profileImg: 'https://www.gravatar.com/avatar/?d=identicon'
  };


  //Navigation configuration
  navigationItems: DsNavigationItem[] = [
    {
      label: 'Fahrstraßen',
      href: '/roadways',
    },
    {
      label: 'Fahrzeuge',
      href: '/vehicles/all',
      icon: 'car',
    },
    {
      label: 'Besucher & Termine',
      href: '/visitorsandappointments',
      icon: 'todo',
    },
  ];

  title = 'Empfangsassistent';
}
