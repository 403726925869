import { Component, computed, effect, EventEmitter, input, Output, signal } from '@angular/core';
import { DsButtonModule, DsFormsModule, DsMessageModule, DsModalModule, DsSelectModule, DsToastService, SelectListOption } from '@bmw-ds/components';
import { FormsModule } from '@angular/forms';
import { CommonModule, JsonPipe } from '@angular/common';
import { PairingService } from '../shared/pairing/pairing.service';
import { PairingUpsert } from '../shared/pairing/pairing-upsert.model';
import { ValidLocationNameValidatorDirective } from '../shared/location.directive';

@Component({
  selector: 'app-pairing-dialog',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    DsFormsModule,
    DsModalModule,
    DsSelectModule,
    DsButtonModule,
    DsMessageModule,
    JsonPipe,
    ValidLocationNameValidatorDirective,
  ],
  templateUrl: './pairing-dialog.component.html',
  styleUrl: './pairing-dialog.component.scss'
})
export class PairingDialogComponent {
  @Output() dismissModalEvent = new EventEmitter<void>();
  @Output() pairingResultEvent = new EventEmitter<boolean>();

  license_plate = input.required<string>();
  pairing = input<Partial<PairingUpsert> | null>();

  reason = signal<"Gast"|"Service">("Gast");
  isNew = computed(() => typeof this.pairing() === "undefined" || this.pairing() === null);
  public serviceOptions: SelectListOption[] = [ {id: 'Service', label: 'Service'}, {id: 'Gast', label: 'Gast'} ];
  guideNumber = signal(0);

  constructor(
    private pairingService: PairingService,
    private toastService: DsToastService,
  ) {
    effect(() => {
      const pairing = this.pairing();
      this.reason.set(pairing?.reason ?? "Gast");
      this.guideNumber.set(pairing?.guideNumber ?? 0);
    }, { allowSignalWrites: true });
  }

  public showModal = true;

  public async onSubmit() {
    try {
      await this.pairingService.upsertPairing(this.license_plate(), {
        reason: this.reason(),
        guideNumber: this.guideNumber(),
      }, this.isNew());
      this.toastService.pushToast({
        toastText: 'Pairing successful',
        tone: 'positive'
      });
    } catch (err) {
      this.toastService.pushToast({
        toastText: 'An error occurred',
        tone: 'critical'
      });
    }
    this.dismissModalEvent.emit();
  }

  public dismissModal(): void {
    this.dismissModalEvent.emit();
  }

  public handleServiceSelection(item: SelectListOption | SelectListOption[] | null) {
    if (item && 'id' in item) {
      this.reason.set(item.id as 'Gast'|'Service');
    }
  }
}
