<ds-box>
    <ds-box-header>Fahrzeuge</ds-box-header>
    <ds-box-content>
        <ag-grid-angular
            class="ag-theme-density"
            domLayout="autoHeight"
            [rowData]="vehicle()"
            [columnDefs]="columns()"
            [gridOptions]="gridOptions()"
            [rowHeight]="70"
            [autoSizeStrategy]="autoSizeStrategy"
            [defaultColDef]="defaultColDef()"
        >
        </ag-grid-angular>
    </ds-box-content>
</ds-box>
