import { Component, signal } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { VehicleStayService } from '../shared/vehicle-stay/vehicle-stay.service';
import { CommonModule } from '@angular/common';
import { DsButtonModule, DsToastService } from '@bmw-ds/components';
import { DsIconModule } from '@bmw-ds/components';
import { PairingDialogComponent } from '../pairing-dialog/pairing-dialog.component';
import { VehicleStay } from '../shared/vehicle-stay/vehicle-stay.model';


@Component({
  selector: 'app-button-cell-renderer',
  standalone: true,
  imports: [
    CommonModule,
    DsButtonModule,
    DsIconModule,
    PairingDialogComponent,
  ],
  templateUrl: './vehicle-all-button-cell-renderer.component.html',
  styleUrl: './vehicle-all-button-cell-renderer.component.scss'
})
export class VehicleAllButtonCellRendererComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams<VehicleStay>;
  vehicleService!: VehicleStayService;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    return true;
  }

  onClick(): void {
    this.showPairingDialog.set(true);
  }

  public showPairingDialog = signal(false);

  public constructor(
    private toastService: DsToastService
  ) { }

  public closeModal(): void {
    this.showPairingDialog.set(false);
  }

  public showPairingResult(success: boolean): void {
    this.toastService.pushToast({
      toastText: success ? 'Pairing Sucessfull' : 'Pairing not created',
      tone: success ? 'positive' : 'critical'
    });
    this.showPairingDialog.set(false);
  }

}
