import { EventEmitter } from "@angular/core";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
  })
export default class GridExportEvent {
    private eventEmitter: EventEmitter<object> = new EventEmitter();

    public emitEvent(event: object): void {
        this.eventEmitter.emit(event);
    }

    public getEventEmitter(): EventEmitter<object> {
        return this.eventEmitter;
    }
}