import { Component, OnDestroy, ViewChild, computed, effect, signal } from '@angular/core';
import { DsBoxModule, DsButtonModule, DsLoadingAreaConfiguration, DsLoadingAreaModule, DsLoadingAreaOverlayContainerDirective, DsLoadingAreaService, DsToastModule, DsToastService } from '@bmw-ds/components';
import { DsCheckboxModule, DsFormFieldModule } from '@bmw-ds/components';
import { FormsModule } from '@angular/forms';
import GridExportEvent from './grid-export-event';
import { VehicleStayService } from '../shared/vehicle-stay/vehicle-stay.service';
import { VehicleStay } from '../shared/vehicle-stay/vehicle-stay.model';
import { VisitorsAndAppointmentsAgGridComponent } from '../visitors-and-appointments-ag-grid/visitors-and-appointments-ag-grid.component';

@Component({
  selector: 'app-visitors-and-appointments',
  standalone: true,
  imports: [
    DsBoxModule,
    DsToastModule,
    DsLoadingAreaModule,
    VisitorsAndAppointmentsAgGridComponent,
    DsCheckboxModule,
    DsFormFieldModule,
    FormsModule,
    DsButtonModule
  ],
  templateUrl: './visitors-and-appointments.component.html',
  styleUrl: './visitors-and-appointments.component.scss'
})
export class VisitorsAndAppointmentsComponent implements OnDestroy {

  isLoading = signal(true);
  currentDate = new Date();
  viewSelectionAll = signal(true);
  viewSelectionTomorrow = signal(false);
  viewSelectionToday = signal(false);
  viewSelectionOpen = signal(false);
  viewSelectionInHouse = signal(false);

  relevantAppointments = computed(() => {
    const data = this.vehicleStayService.data();
    return data.filter((vehicle) => vehicle.appointment && vehicle.appointment.appointmentDatetime);
  });

  appointments = computed(() => {
    const data = this.relevantAppointments();
    let filteredData: VehicleStay[] = [];
    if (this.viewSelectionAll()) {
      return data;
    }

    if (this.viewSelectionTomorrow() && this.viewSelectionToday()) {
      const tomorrow = new Date(this.currentDate);
      tomorrow.setDate(tomorrow.getDate() + 1);
      filteredData = filteredData.concat(data.filter(vehicleStay => vehicleStay.appointment && this.isSameDay(vehicleStay.appointment.appointmentDatetime, tomorrow) || vehicleStay.appointment && this.isSameDay(vehicleStay.appointment.appointmentDatetime, this.currentDate)));
    }
    if (this.viewSelectionTomorrow()) {
      const tomorrow = new Date(this.currentDate);
      tomorrow.setDate(tomorrow.getDate() + 1);
      filteredData = filteredData.concat(data.filter(vehicleStay => vehicleStay.appointment && this.isSameDay(vehicleStay.appointment.appointmentDatetime, tomorrow)));
    }
    if (this.viewSelectionToday()) {
      filteredData = filteredData.concat(data.filter(vehicleStay => vehicleStay.appointment && this.isSameDay(vehicleStay.appointment.appointmentDatetime, this.currentDate)));
    }
    if (this.viewSelectionOpen() || this.viewSelectionInHouse()) {
      if (this.viewSelectionOpen()) {
        filteredData = data.filter(vehicleStay => !vehicleStay.transitedDatetime);
      }
      if (this.viewSelectionInHouse()) {
        filteredData = filteredData.concat(data.filter(vehicleStay => vehicleStay.transitedDatetime && !vehicleStay.exitedDatetime));
      }
    }

    return filteredData;
  });

  appointmentsCount = computed(() => {
    const data = this.relevantAppointments();
    return data.length;
  });

  appointmentsTodayCount = computed(() => {
    return this.relevantAppointments().filter(vehicleStay => vehicleStay.appointment && this.isSameDay(vehicleStay.appointment.appointmentDatetime, this.currentDate)).length;
  });

  appointmentsTomorrowCount = computed(() => {
    const data = this.relevantAppointments();
    const tomorrow = new Date(this.currentDate);
    tomorrow.setDate(tomorrow.getDate() + 1);
    return data.filter(vehicleStay => vehicleStay.appointment && this.isSameDay(vehicleStay.appointment.appointmentDatetime, tomorrow)).length;
  });

  appointmentsOpenCount = computed(() => {
    const data = this.relevantAppointments();
    return data.filter(vehicleStay => !vehicleStay.transitedDatetime).length;
  });

  appointmentsInHouseCount = computed(() => {
    const data = this.relevantAppointments();
    return data.filter(vehicleStay => vehicleStay.transitedDatetime && !vehicleStay.exitedDatetime).length;
  });

  @ViewChild(DsLoadingAreaOverlayContainerDirective)
  loadingAreaContainer!: DsLoadingAreaOverlayContainerDirective;

  private static instance = 0;
  private loadingAreaID = `VisitorsAndAppointmentsComponent-${VisitorsAndAppointmentsComponent.instance++}`;

  private loadingAreaConfig: DsLoadingAreaConfiguration = {
    id: this.loadingAreaID,
    container: this.loadingAreaContainer,
  }

  constructor(
    private vehicleStayService: VehicleStayService,
    private toastService: DsToastService,
    private loadingAreaService: DsLoadingAreaService,
    private gridExportEvent: GridExportEvent,
  ) {
    effect(() => {
      if (this.isLoading()) {
        this.loadingAreaService.addLoadingArea(this.loadingAreaConfig);
      } else {
        this.loadingAreaService.removeLoadingArea(this.loadingAreaID);
      }
    });
    this.loadData();
  }

  async loadData() {
    try {
      await this.vehicleStayService.loadAll();
    } catch (err) {
      this.toastService.pushToast({
        id: 'error-toast',
        tone: 'critical',
        toastText: 'Error while loading. Please try again in a few minues.'
      })
    } finally {
      this.isLoading.set(false);

    }
  }

  ngOnDestroy(): void {
    this.toastService.clearToasts();
  }

  isSameDay(date1: Date, date2: Date): boolean {
    return date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate();
  }

  setViewSelectionAll(value: boolean) {
    this.viewSelectionAll.set(value);
    if (value) {
      this.viewSelectionToday.set(false);
      this.viewSelectionTomorrow.set(false);
      this.viewSelectionOpen.set(false);
      this.viewSelectionInHouse.set(false);
    }
  }

  setViewSelectionToday(value: boolean) {
    this.viewSelectionToday.set(value);
    if (value) {
      this.viewSelectionAll.set(false);
    }
  }

  setViewSelectionTomorrow(value: boolean) {
    this.viewSelectionTomorrow.set(value);
    if (value) {
      this.viewSelectionAll.set(false);
    }
  }

  setViewSelectionOpen(value: boolean) {
    this.viewSelectionOpen.set(value);
    if (value) {
      this.viewSelectionAll.set(false);
    }
  }

  setViewSelectionInHouse(value: boolean) {
    this.viewSelectionInHouse.set(value);
    if (value) {
      this.viewSelectionAll.set(false);
    }
  }

  public exportGridToCsv(): void {
    this.gridExportEvent.emitEvent({});
  }
}
