import { Component, OnDestroy, ViewChild, computed, effect, signal } from '@angular/core';
import { DsLoadingAreaConfiguration, DsLoadingAreaModule, DsLoadingAreaOverlayContainerDirective, DsLoadingAreaService, DsToastModule, DsToastService } from '@bmw-ds/components';
import { VehicleAgGridComponent } from '../vehicle-ag-grid/vehicle-ag-grid.component';
import { AgGridAngular } from 'ag-grid-angular';
import { VehicleStayService } from '../shared/vehicle-stay/vehicle-stay.service';

@Component({
  selector: 'app-vehicles-notpaired',
  standalone: true,
  imports: [AgGridAngular,
    DsToastModule,
    DsLoadingAreaModule,
    VehicleAgGridComponent],
  templateUrl: './vehicles-notpaired.component.html',
  styleUrl: './vehicles-notpaired.component.scss'
})
export class VehiclesNotpairedComponent implements OnDestroy {

  isLoading = signal(true);
  vehicle = computed(() => this.vehicleService.data());

  @ViewChild(DsLoadingAreaOverlayContainerDirective)
  loadingAreaContainer!: DsLoadingAreaOverlayContainerDirective;

  private static instance = 0;
  private loadingAreaID = `VehiclesAllComponent-${VehiclesNotpairedComponent.instance++}`;

  private loadingAreaConfig: DsLoadingAreaConfiguration = {
    id: this.loadingAreaID,
    container: this.loadingAreaContainer,
  }

  constructor(
    private vehicleService: VehicleStayService,
    private toastService: DsToastService,
    private loadingAreaService: DsLoadingAreaService,
  ) {
    effect(() => {
      if (this.isLoading()) {
        this.loadingAreaService.addLoadingArea(this.loadingAreaConfig);
      } else {
        this.loadingAreaService.removeLoadingArea(this.loadingAreaID);
      }
    });
    this.loadData();
  }

  async loadData() {
    try {
      await this.vehicleService.loadAll();
    } catch (err) {
      this.toastService.pushToast({
        id: 'error-toast',
        tone: 'critical',
        toastText: 'Error while loading. Please try again in a few minues.'
      })
    } finally {
      this.isLoading.set(false);
    }
  }

  ngOnDestroy(): void {
    this.toastService.clearToasts();
  }
}
