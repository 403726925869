import { Component, input, signal } from '@angular/core';
import { DsLoadingAreaModule, DsToastModule, } from '@bmw-ds/components';

import { AgGridAngular } from 'ag-grid-angular';

import { ClientSideRowModelModule, ICellRendererParams, SizeColumnsToContentStrategy, ValueFormatterParams } from 'ag-grid-community';
import {
  ColDef,
  GridOptions,
  ModuleRegistry,
} from "ag-grid-community";
import { ColumnsToolPanelModule, RowGroupingModule, SideBarDef } from "ag-grid-enterprise";
import { JsonPipe } from '@angular/common';
import { GridReadyEvent } from 'ag-grid-community';
import { GridApi } from 'ag-grid-community';
import { VehicleStay } from '../shared/vehicle-stay/vehicle-stay.model';
import GridExportEvent from '../visitors-and-appointments/grid-export-event';
import { HintCellRendererComponent } from '../hint-cell-renderer/hint-cell-renderer.component';
import { VehicleStatusCellRendererComponent } from '../vehicle-status-cell-renderer/vehicle-status-cell-renderer.component';
ModuleRegistry.registerModules([ClientSideRowModelModule, RowGroupingModule, ColumnsToolPanelModule]);

@Component({
  selector: 'app-visitors-and-appointments-ag-grid',
  standalone: true,
  imports: [AgGridAngular,
    DsToastModule,
    DsLoadingAreaModule,
    JsonPipe],
  templateUrl: './visitors-and-appointments-ag-grid.component.html',
  styleUrl: './visitors-and-appointments-ag-grid.component.scss'
})
export class VisitorsAndAppointmentsAgGridComponent {
  appointments = input.required<VehicleStay[]>();
  gridApi!: GridApi;

  constructor(private gridExportEvent: GridExportEvent) {
    this.exportGridToCsv = this.exportGridToCsv.bind(this);
    this.gridExportEvent.getEventEmitter().subscribe(this.exportGridToCsv);
  }

  private exportGridToCsv(): void {
    if (this.gridApi) {
      this.gridApi.exportDataAsCsv();
    }
  }

  public onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  public sideBar: SideBarDef | string | string[] | boolean | null = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",

        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
        },
      },
    ],
    defaultToolPanel: "",
  };

  columns = signal<ColDef<VehicleStay>[]>([
    {
      field: 'appointment.appointmentDatetime',
      headerName: 'Termin',
      valueFormatter: (params: ValueFormatterParams) => {
        const date = new Date(params.value);
        return date.toLocaleString('de-DE', { hour: '2-digit', minute: '2-digit' });
      }
    },
    {
      field: 'identifiedDatetime',
      headerName: 'Ankunft',
      valueFormatter: (params: ValueFormatterParams) => {
        if (!params.value) {
          return "";
        }
        const date = new Date(params.value);
        return date.toLocaleString('de-DE', { hour: '2-digit', minute: '2-digit' });
      }
    },
    {
      headerName: 'Verspätung',
      cellRenderer: HintCellRendererComponent,
      cellRendererParams: {
        dateFieldKey: 'appointment.appointmentDatetime'
      }
    },
    {
      headerName: 'Status',
      hide: false,
      cellRenderer: VehicleStatusCellRendererComponent,
    },
    {
      field: 'vehicle.customer.lastName',
      headerName: 'Kunde',
      cellRenderer: (params: ICellRendererParams) => {
        return params.data.vehicle?.customer?.lastName + ' ' + params.data.vehicle?.customer?.firstName;
      },
    },
    {
      field: 'vehicle.customer.driver',
      headerName: 'Fahrer',
    },
    {
      field: 'vehicle.customer.companyName',
      headerName: 'Firma',
    },
    {
      field: 'vehicle.visitReason',
      headerName: 'Grund',
    },
    {
      field: 'vehicle.licensePlate',
      headerName: 'Kennzeichen',
    },
    {
      field: 'vehicle.carType',
      headerName: 'Modell',
    },
    {
      field: 'vehicle.internalContact',
      headerName: 'Interner Kontakt',
    }
  ]);
  gridOptions = signal<GridOptions>({
  });
  defaultColDef = signal<ColDef>({
    sortable: true,
    filter: true,
    suppressNavigable: true,
    editable: false,
  });
  statusBar = {
    statusPanels: [
      {
        statusPanel: 'agTotalRowCountComponent',
      },
    ],
  };

  autoSizeStrategy: SizeColumnsToContentStrategy = {
    type: 'fitCellContents',
  }
}
