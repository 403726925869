import { Injectable, Signal } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export abstract class DataService<T> {

  /**
   * Returns true if data is automatically updated via an active websocket connection.
   */
  public abstract dataChangesAreMonitored: Signal<boolean>;

  /**
   * The current data.
   */
  public abstract data: Signal<T[]>;

  /**
   * Loads all data from the backend to the data signal.
   * Also starts change monitoring if a websocket connection is defined and keeps the data signal automatically up to date.
   */
  public abstract loadAll(): Promise<T[]>;
}
