<ds-box>
    <ds-box-header>
        <div class="row">
            <div class="col-6">
                <h3>{{stream.name}}</h3>
            </div>
            <div class="d-flex col-6 justify-content-end">
                <a *ngIf="stream.barrierControlUrl" ds-button variant="primary" icon="settings" target="_blank" [href]="stream.barrierControlUrl" aria-label="Schrankensteuerung"></a>
            </div>
        </div>
    </ds-box-header>
    <ds-box-content>
        <video autoplay="true" crossorigin="use-credentials" controlslist="nodownload" [poster]="stream.placeholderImageUrl" muted="true" controls>
            <source [src]="stream.previewCameraUrl" type="video/mp4" />
            <source media="(min-width:1000px)" [src]="stream.fullScreenCameraUrl" type="video/mp4">
        </video>
    </ds-box-content>
</ds-box>
