import { CommonModule } from '@angular/common';
import { Component, computed, Signal } from '@angular/core';
import { DsBoxModule, DsTabsModule } from '@bmw-ds/components';
import { AppointmentComponent } from '../appointment/appointment.component';
import { Appointment } from '../shared/appointment/appointment.model';
import { AppointmentService } from '../shared/appointment/appointment.service';
import { DateService } from '../shared/date/date.service';

@Component({
  selector: 'app-appointments-list',
  standalone: true,
  imports: [
    CommonModule,
    DsBoxModule,
    DsTabsModule,
    AppointmentComponent
  ],
  templateUrl: './appointments-list.component.html',
  styleUrl: './appointments-list.component.scss'
})
export class AppointmentsListComponent {
  constructor(private dateService: DateService,
    private appointmentService: AppointmentService
  ) {
    this.appointmentService.loadAll();
    this.appointments = this.appointmentService.data;
    this.impendingAppointments = computed(() => {
      const appointments = this.appointmentService.data();
      return appointments?.filter((appointment) => {
        const today = new Date();
        const deltaMinutes = this.dateService.getTimeDeltaInMinutes(appointment.appointmentDatetime, today);
        return (today.getDate() === appointment.appointmentDatetime.getDate()) && (deltaMinutes > 0 && deltaMinutes <= 30);
      });
    });
    this.lateAppointments = computed(() => {
      const now = new Date();
      const appointments = this.appointmentService.data();
      return appointments?.filter((appointment) => {
        return now > appointment.appointmentDatetime;
      });
    });
  }
  public appointments: Signal<Appointment[]>;
  public impendingAppointments: Signal<Appointment[]>;
  public lateAppointments: Signal<Appointment[]>;
}
