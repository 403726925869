@switch (params?.value?.direction) {
    @case ("in") {
        <ds-icon icon="arrow_right_circle" size="lg" /><br>
        Einfahrt
    }
    @case ("out") {
        <ds-icon icon="arrow_left_circle" size="lg" /><br>
        Ausfahrt
    }
    @default {
    }
}
