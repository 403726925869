import { Injectable } from '@angular/core';
import { VehicleStay } from './vehicle-stay.model';
import { DataService } from '../data/data.service';

@Injectable({
  providedIn: 'root'
})
export abstract class VehicleStayService extends DataService<VehicleStay> {
}


