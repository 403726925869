@if (status()) {
    <ds-tag [tone]="tone()">
    @switch (status()) {
        @case ("open") {
            Offen
        }
        @case ("inHouse") {
            Im Haus
        }
        @case ("waiting") {
            Kunde wartet
        }
        @case ("completed") {
            Abgeschlossen
        }
    }
    </ds-tag>
    
}