<div class="vehicles">
  <!-- <ds-tabs [navigateOnSelect]="true" contentSpacing="none">
        <ds-tabs-label for="Alle" path="/vehicles/all">Alle</ds-tabs-label>
        <ds-tabs-label for="Auf dem Gelände" path="/vehicles/onsite">Auf dem Gelände</ds-tabs-label>
        <ds-tabs-label for="Nicht verheiratet" path="/vehicles/notpaired">Nicht verheiratet</ds-tabs-label>
    </ds-tabs> -->

  <h1>Fahrzeuge</h1>
  <div class="row mb-5x">
    <ds-form-field [optionalText]="' '" class="col-11">
      <ds-checkbox-group
        [isBlock]="true"
        variant="inline"
        name="viewSelection"
      >
        <input
          ds-input
          id="toggleGroup"
          type="checkbox"
          name="toggleGroup"
          [value]="true"
          [ngModel]="groupModeIsActive()"
          (ngModelChange)="setGroupModeisActive($event)"
        />
        <label ds-label for="toggleGroup">Gruppierung</label>
        <input
          ds-input
          id="all"
          type="checkbox"
          name="viewSelectionAll"
          [value]="true"
          [ngModel]="viewSelectionAll()"
          (ngModelChange)="setViewSelectionAll($event)"
        />
        <label ds-label for="all">Alle Fahrzeuge</label>
        <input
          ds-input
          id="onarea"
          type="checkbox"
          name="viewSelectionIsOnArea"
          [value]="true"
          [ngModel]="viewSelectionIsOnArea()"
          (ngModelChange)="setViewSelectionIsOnArea($event)"
        />
        <label ds-label for="onarea">Auf dem Gelände</label>
        <input
          ds-input
          id="divorced"
          type="checkbox"
          name="viewSelectionIsNotMarried"
          [value]="true"
          [ngModel]="viewSelectionIsNotMarried()"
          (ngModelChange)="setViewSelectionIsMarried($event)"
        />
        <label ds-label for="divorced">Nicht verheiratet</label>
      </ds-checkbox-group>
    </ds-form-field>
    <div class="col-1 align-content-center">
      <button type="button" ds-button variant="ghost" icon="download" (click)="exportGridToCsv()">
        <span></span>
      </button>
      <button ds-button [ds-popover-trigger-for]="popoverId" variant="ghost" aria-label="Tabelle filtern">
        <ds-icon icon="filter"></ds-icon>
      </button>
      <ds-popover #popoverId>
        <ds-box-content>
          <ds-form-field>
            <label ds-label for="filterType">Typ</label>
            <ds-select id="filterType"
              [options]="[
                { id: 'all', label: 'Alle' },
                { id: 'lessThan', label: '<'},
                { id: 'greaterThan', label: '>'},
                { id: 'equals', label: '='},
                { id: 'lessThanOrEquals', label: '<='},
                { id: 'greaterThanOrEquals', label: '>='}
              ]"
              [(ngModel)]="selectedFilterType"></ds-select>
          </ds-form-field>
          <ds-form-field>
            <label ds-label for="numberOfEntriesToCheck">Einfahrten</label>
            <input ds-input type="number" id="numberOfEntriesToCheck" placeholder="0" [(ngModel)]="numberOfEntries" [hideControlOptions]="true" />
          </ds-form-field>
        </ds-box-content>
      </ds-popover>
    </div>
  </div>
  <div ds-loading-area-container>
    <ds-box>
      <ds-box-content>
        <app-vehicle-ag-grid
          [vehicles]="vehicle()"
          [groupModeIsActive]="groupModeIsActive()"
          [selectedFilterType]="selectedFilterType()"
          [entryNumber]="numberOfEntries()"
        ></app-vehicle-ag-grid>
      </ds-box-content>
    </ds-box>
  </div>
</div>
