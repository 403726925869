import { Component, computed, Input } from '@angular/core';
import { DsTagModule } from '@bmw-ds/components';
import { DateService } from '../shared/date/date.service';

@Component({
  selector: 'app-time-hint-tag',
  standalone: true,
  imports: [
    DsTagModule
  ],
  templateUrl: './time-hint-tag.component.html',
  styleUrl: './time-hint-tag.component.scss'
})
export class TimeHintTagComponent {
  @Input() date?: Date | undefined = undefined; 

  constructor(private dateService: DateService) { }

  public tone = computed<string>(() => {
    const now = new Date();
    if (this.date === undefined || this.date == null) {
      return 'critical';
    }

    switch (now > this.date) {
      case true:
        return 'critical';
      case false:
        {
          const deltaMinutes = this.dateService.getTimeDeltaInMinutes(this.date, now);
          return (now.getDate() === this.date.getDate()) && deltaMinutes <= 15 ? 'caution' : deltaMinutes > 30 ? 'info' : 'positive';
        }
    }
  });

  public remainingTime = computed<string>(() => {
    const now = new Date();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (this.date === undefined || this.date == null || !(this.date instanceof Date) || isNaN(this.date as any)) {
      return 'N/A';
    }

    let result: string = 'N/A';
    if (now > this.date) {
      const delta = now.getTime() - this.date.getTime();
      result = `+ ${this.dateService.getTimeInMSToDayHoursAndMinutes(delta)}`;
    } else {
      const delta = this.date.getTime() - now.getTime();
      result = `${this.dateService.getTimeInMSToDayHoursAndMinutes(delta)}`;
    }

    return result;
  });
}
