import { Injectable } from "@angular/core";
import { PairingService } from "./pairing.service";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { PairingUpsert } from "./pairing-upsert.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class PairingBackendService extends PairingService {
  private apiUrl = environment.apiUrl + 'v1/vehicle';

  constructor(private http: HttpClient) {
    super();
  }

  public override async upsertPairing(licensePlate: string, pairing: PairingUpsert, isNew: boolean): Promise<void> {
    if (!pairing) {
      throw new Error('PairingCommand object is required');
    }

    const url: string = `${this.apiUrl}/pair/${licensePlate}`;
    if (isNew) {
      await firstValueFrom(this.http.post(url, pairing));
    } else {
      await firstValueFrom(this.http.put(url, pairing));
    }
  }
}
