import { Component, computed, input, OnInit, signal } from '@angular/core';
import { DsBoxModule } from '@bmw-ds/components';
import { AgGridAngular } from 'ag-grid-angular';
import { VehicleStayService } from '../shared/vehicle-stay/vehicle-stay.service';
import { ColDef, GridOptions, SizeColumnsToContentStrategy } from 'ag-grid-community';
import { VehicleStay } from '../shared/vehicle-stay/vehicle-stay.model';
import { DateService } from '../shared/date/date.service';
import { Lane } from '../shared/streams/lane.model';
import { VehicleAllButtonCellRendererComponent } from '../vehicle-all-button-cell-renderer/vehicle-all-button-cell-renderer.component';
import { ActionsCellRendererComponent } from '../actions-cell-renderer/actions-cell-renderer.component';
import { CustomerCellRendererComponent } from '../customer-cell-renderer/customer-cell-renderer.component';
import { DirectionCellRendererComponent } from '../direction-cell-renderer/direction-cell-renderer.component';
import { HintCellRendererComponent } from '../hint-cell-renderer/hint-cell-renderer.component';

@Component({
  selector: 'app-vehicles-list',
  standalone: true,
  imports: [
    DsBoxModule,
    AgGridAngular,
  ],
  templateUrl: './vehicles-list.component.html',
  styleUrl: './vehicles-list.component.scss'
})
export class VehiclesListComponent implements OnInit{
  lanes = input.required<Lane[]>();
  
  constructor(
    private vehicleService: VehicleStayService,
    private dateService: DateService
  ) { }

  async ngOnInit() {
      await this.vehicleService.loadAll();
  }

  autoSizeStrategy: SizeColumnsToContentStrategy = {
    type: 'fitCellContents',
  }

  vehicle = computed(() => {
    const lanes = this.lanes();
    const laneIds = lanes.map(value => value.laneId);
    return this.vehicleService.data().filter(vehicle => laneIds.includes(vehicle.lane))
  });

  columns = signal<ColDef<VehicleStay>[]>([
    {
      field: 'vehicle.licensePlate',
      headerName: 'Kennzeichen',
    },
    {
      // valueGetter: d => this.lanes().filter(lane => lane.laneId === d.data?.lane)[0].direction,
      headerName: 'Richtung',
      cellRendererSelector: params => {
        return {
          component: DirectionCellRendererComponent,
          params: {value: this.lanes().filter(lane => lane.laneId === params.data?.lane)[0]}
        };
      },
      cellStyle: {textAlign: 'center'}
    },
    {
      valueGetter: d => this.lanes().filter(lane => lane.laneId === d.data?.lane)[0]?.name,
      headerName: 'Fahrstraße',
    },
    {
      field: 'identifiedDatetime',
      valueFormatter: p => p.value?.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
      headerName: 'Zeit',
      sort: 'desc',
    },
    {
      field: 'vehicle.visitReason',
      headerName: 'Grund',
    },
    {
      valueGetter: d => d.data && d.data.identifiedDatetime ? Math.round(this.dateService.getTimeDelta(d.data.exitedDatetime ? d.data.exitedDatetime : new Date(), d.data.identifiedDatetime)) : null,
      valueFormatter: p => {
        const msTotal = p.value;
        const minTotal = msTotal / 1000 / 60;
        const hours = Math.floor(minTotal / 60);
        const min = Math.round(minTotal % 60);
        return `${hours}h ${min.toString().padStart(2, "0")}m`;
      },
      headerName: 'Dauer',
    },
    {
      field: 'appointment.appointmentDatetime',
      valueFormatter: p => p.value?.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
      headerName: 'Termin',
    },
    {
      headerName: 'Kunde',
      cellRenderer: CustomerCellRendererComponent,
      cellStyle: {'white-space': 'normal'},
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: 'Firma',
      field: 'vehicle.customer.companyName',
    },
    {
      headerName: 'Warnung',
      cellRenderer: HintCellRendererComponent
    },
    {
      field: 'vehicle.pairing',
      headerName: 'Verheiratet',
      cellRenderer: VehicleAllButtonCellRendererComponent,
    },
    {
      sortable: false,
      filter: false,
      cellRendererSelector: params => {
        return {
          component: ActionsCellRendererComponent,
          params: {
            value: {
              lane: this.lanes().filter(lane => lane.laneId === params.data?.lane)[0],
              vehicle: params.data,
            }
          }
        };
      },
    }
  ]);
  gridOptions = signal<GridOptions>({
    statusBar: {
      statusPanels: [
          // { statusPanel: 'agTotalAndFilteredRowCountComponent' },
          { statusPanel: 'agTotalRowCountComponent' },
          // { statusPanel: 'agFilteredRowCountComponent' },
          // { statusPanel: 'agSelectedRowCountComponent' },
          // { statusPanel: 'agAggregationComponent' }
      ]
  }});
  defaultColDef = signal<ColDef>({
    sortable: true,
    filter: true,
    suppressNavigable: true,
    editable: false,
  });
}
