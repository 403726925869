import { Injectable } from '@angular/core';

import { Appointment } from './appointment.model';
import { DataService } from '../data/data.service';

@Injectable({
  providedIn: 'root'
})
export abstract class AppointmentService extends DataService<Appointment> {
}
