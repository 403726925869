import { Component, Input } from '@angular/core';
import { DsTagModule } from '@bmw-ds/components';
import { Appointment } from '../shared/appointment/appointment.model';
import { TimeHintTagComponent } from '../time-hint-tag/time-hint-tag.component';

@Component({
  selector: 'app-appointment',
  standalone: true,
  imports: [
    DsTagModule,
    TimeHintTagComponent,
  ],
  templateUrl: './appointment.component.html',
  styleUrl: './appointment.component.scss'
})
export class AppointmentComponent {
  @Input() appointment!: Appointment;

  constructor(){}
}
