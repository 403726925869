<ds-modal #pairingFormModal [ds-modal-trigger-for]="pairingFormModal" [(isModalOpen)]="showModal" (dismiss)="dismissModal()" size="sm">
        <ds-box-header>Verheiraten</ds-box-header>
        <ds-box-content>
            <form ds-form (ngSubmit)="onSubmit()" #pairingForm="ngForm">
                <ds-form-field class="mb-4x">
                    <label ds-label for="licensePlate">Kennzeichen</label>
                    <input
                        ds-input
                        ds-form-validation
                        [ngModel]="license_plate()"
                        [readonly]="true"
                        name="licensePlate"
                        #licensePlate="ngModel"
                        type="text"
                        placeholder="Geben Sie hier ein Kennzeichen ein"
                        required />
                    <ds-error *ngIf="licensePlate.hasError('required')">Bitte geben Sie ein Kennzeichen an</ds-error>
                </ds-form-field>
                <ds-form-field class="mb-4x">
                    <label ds-label for="serviceReason">Besuchsgrund</label>
                    <ds-select 
                        [options]="serviceOptions"
                        ds-form-validation
                        #serviceReason="ngModel"
                        (optionSelected)="handleServiceSelection($event)"
                        name="serviceReason"
                        required
                        [ngModel]="reason()"
                        ></ds-select>
                        <ds-error *ngIf="serviceReason.hasError('required')">Bitte geben Sie einen Besuchsgrund an</ds-error>
                </ds-form-field>
                <ds-form-field [hidden]="!reason" class="mb-4x">
                    <label ds-label for="locationProvider">Leitzahl</label>
                    <input
                        ds-input
                        ds-form-validation
                        [(ngModel)]="guideNumber"
                        name="locationProvider"
                        #locationProvider="ngModel"
                        type="number"
                        placeholder="Geben Sie hier eine Leitzahl ein"
                        appValidLocationName
                        [ngModelOptions]="{updateOn: 'blur'}"
                        required />
                    <ds-error *ngIf="locationProvider.hasError('required')">Bitte geben Sie eine Leitzahl an</ds-error>
                    <ds-error *ngIf="locationProvider.hasError('validName')">Ungültige Leitzahl</ds-error>
                </ds-form-field>
                <div class="d-flex justify-content-end">
                    <button ds-button icon="link" type="submit" [disabled]="!pairingForm.valid" class="me-2x">Verheiraten ohne Schlüssel</button>
                    <button ds-button variant="outline" (click)="dismissModal()" type="button">Abbrechen</button>
                </div>
            </form>
        </ds-box-content>
    </ds-modal>